import React from 'react';
import { graphql } from 'gatsby';
import { Link } from "gatsby"
import Layout from '../components/layout';
import SEO from '../components/seo'


const PostTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html, excerpt, rawMarkdownBody } = markdownRemark;

    const url = data.site.siteMetadata.url + markdownRemark.fields.slug;

    let img = null;
    let imgW = 0;
    let imgH = 0;

    if (frontmatter.icon) {
        img = frontmatter.icon.childImageSharp.fluid.src;
        imgW = frontmatter.icon.childImageSharp.fluid.src.presentationWidth;
        imgH = frontmatter.icon.childImageSharp.fluid.src.presentationHeight;
    }

  const tags = (frontmatter.tags) ? frontmatter.tags : "Post Writting Poet";
  const schema = {
        "@context": "https://schema.org",
        "@type": "Article",
        "name": "Post | "+frontmatter.title,
        "url": url,
        "description": excerpt,
        "headline": frontmatter.title,
        "datePublished": frontmatter.date,
        "articleBody": rawMarkdownBody,
        "keywords": tags.split(" "),
        "author": {
            "@type": "Person",
            "name": "Christian Lacdael",
            "url": data.site.siteMetadata.url+"/christian-lacdael",
        },
        "@id": url,
    };

if (img) schema["image"] = {"@type":"ImageObject","url":(url+img),"width":imgW,"height":imgH};

return (
    <Layout>
      <SEO title={ frontmatter.title }  description={excerpt} tags={tags} date={ frontmatter.date } image={img} what="article" schemaMarkup={ schema } />
      <section style={{margin:0.5+`em`,overflow:`hidden`}}>
       <ul className="breadcrumb">
        <li><Link to="/">Home</Link></li>
       <li><Link to="/blog">Blog</Link></li>
        <li>{frontmatter.title}</li>
        </ul>
      <div>
      <h1>{frontmatter.title}</h1>
          <em>{frontmatter.date}</em>
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </Layout>
  );
};

export default PostTemplate;



export const pageQuery = graphql`
  query($slug: String!) {
  	site {
        siteMetadata {
            url
        }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 250)
      rawMarkdownBody
      fields{
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        icon {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                    src
                    presentationHeight
                    presentationWidth
                    }
                }
            }
        }
  }
}`;
